import React from 'react';
import { StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { sortArray } from 'magmel';

// components
import { Navbar, Banner, Footer } from 'src/components/common';
import { SectionHeader, ShootPage } from 'src/components/Photography';

const VeryGhanaian = () => {
  return (
    <>
      <ShootPage
        style={{
          marginTop: '100px',
        }}
      >
        <Navbar dark />
        <div className="container-lg">
          <SectionHeader sectionName="very ghanaian" />
        </div>

        <Grid />
        {/* <div className="write-up container-lg">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus ipsa repudiandae
            nemo cumque facilis minima, numquam, molestiae perferendis laboriosam eaque vel non
            quaerat tempore iure vitae. Quidem autem sit ducimus.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facilis, cum. Debitis,
            blanditiis voluptatem placeat unde fugiat laudantium cum ratione velit, ab enim
            repudiandae. Esse consequatur, reiciendis animi delectus at magni!
          </p>
        </div> */}
      </ShootPage>

      <Banner />
      <Footer />
    </>
  );
};

const Grid = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(filter: { relativePath: { regex: "/photography/ads/very-ghanaian/" } }) {
            nodes {
              name
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                  originalImg
                }
              }
            }
          }
        }
      `}
      render={({ allFile }) => {
        const sortedArray = sortArray({
          array: allFile.nodes,
          order: 'asce',
          key: 'name',
        });

        return (
          <div className="horizontal-grid">
            {/* <a class="right-arrow left-arrow">
              <svg width={25} height={25} viewBox="0 0 512 512">
                <path
                  fill="none"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="48px"
                  d="M268 112L412 256 268 400"
                />
                <path
                  fill="none"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="48px"
                  d="M392 256L100 256"
                />
              </svg>
            </a> */}
            <a class="right-arrow">
              <svg width={25} height={25} viewBox="0 0 512 512">
                <path
                  fill="none"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="48px"
                  d="M268 112L412 256 268 400"
                />
                <path
                  fill="none"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="48px"
                  d="M392 256L100 256"
                />
              </svg>
            </a>

            <div className="horizontal-full hs portriats">
              {sortedArray.map((file, index) => {
                return (
                  <Img
                    className="horizontal-image"
                    fluid={file.childImageSharp.fluid}
                    key={index}
                  />
                );
              })}
            </div>
          </div>
        );
      }}
    />
  );
};

export default VeryGhanaian;
